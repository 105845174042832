export default {
  getPieOptions({ name = 'pie', val = '100', data }) {
    let txt = val + '%'
    return {
      title: {
        show: true,
        text: txt + '',
        x: 'center',
        y: 'center',
        textStyle: {
          fontWeight: 'normal',
          color: '#fff',
          fontSize: '14',
        },
      },
      color: '#49bcf7',
      series: [
        {
          name: name,
          type: 'pie',
          clockWise: true,
          radius: ['65%', '80%'],
          itemStyle: {
            normal: {
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
            },
          },
          hoverAnimation: false,
          data,
        },
      ],
    }
  },
  getBarOptions({ series, xData }) {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
      },
      legend: {
        x: 'center',
        y: '0',
        icon: 'circle',
        itemGap: 8,
        textStyle: { color: 'rgba(255,255,255,.5)' },
        itemWidth: 10,
        itemHeight: 10,
      },
      grid: {
        left: '0',
        top: '30',
        right: '15',
        bottom: '0',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: xData,
        axisLine: { show: false },

        axisLabel: {
          textStyle: {
            color: 'rgba(255,255,255,.6)',
            fontSize: 14,
          },
        },
      },
      yAxis: {
        type: 'value',
        splitNumber: 4,
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: {
          show: true,
          lineStyle: {
            color: 'rgba(255,255,255,0.05)',
          },
        },
        axisLabel: {
          textStyle: {
            color: 'rgba(255,255,255,.6)',
            fontSize: 14,
          },
        },
      },
      series,
    }
  },
  getLineOptions({ series, xData }) {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          lineStyle: {
            color: '#dddc6b',
          },
        },
      },
      grid: {
        left: '10',
        top: '30',
        right: '20',
        bottom: '0',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: true,
          axisLabel: {
            textStyle: {
              color: 'rgba(255,255,255,.6)',
              fontSize: 14,
            },
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(255,255,255,.1)',
            },
          },
          data: xData,
        },
        {
          axisPointer: { show: false },
          axisLine: { show: false },
          position: 'bottom',
          offset: 20,
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisTick: { show: false },
          axisLine: {
            lineStyle: {
              color: 'rgba(255,255,255,.1)',
            },
          },
          axisLabel: {
            textStyle: {
              color: 'rgba(255,255,255,.6)',
              fontSize: 14,
            },
          },

          splitLine: {
            lineStyle: {
              color: 'rgba(255,255,255,.1)',
            },
          },
        },
      ],
      series,
    }
  },
  getRadarOptions() {
    return {
      tooltip: {
        trigger: 'axis',
      },
      radar: [
        {
          indicator: [
            {
              text: '盈利能力',
              max: 100,
            },
            {
              text: '发展水平',
              max: 100,
            },
            {
              text: '融资能力',
              max: 100,
            },
            {
              text: '技术能力',
              max: 100,
            },
            {
              text: '企业规模',
              max: 100,
            },
          ],
          textStyle: {
            color: 'red',
          },
          center: ['50%', '50%'],
          radius: '70%',
          startAngle: 90,
          splitNumber: 4,
          shape: 'circle',

          name: {
            padding: -5,
            formatter: '{value}',
            textStyle: {
              fontSize: 14,
              color: 'rgba(255,255,255,.6)',
            },
          },
          splitArea: {
            areaStyle: {
              color: 'rgba(255,255,255,.05)',
            },
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(255,255,255,.05)',
            },
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(255,255,255,.05)',
            },
          },
        },
      ],
      series: [
        {
          name: '雷达图',
          type: 'radar',
          tooltip: {
            trigger: 'item',
          },
          data: [
            {
              name: '园区平均值',
              value: [90, 80, 20, 10, 30],
              lineStyle: {
                normal: {
                  color: '#03b48e',
                  width: 2,
                },
              },
              areaStyle: {
                normal: {
                  color: '#03b48e',
                  opacity: 0.4,
                },
              },
              symbolSize: 0,
            },
            {
              name: '当前园区',
              value: [30, 20, 75, 80, 70],
              symbolSize: 0,
              lineStyle: {
                normal: {
                  color: '#3893e5',
                  width: 2,
                },
              },
              areaStyle: {
                normal: {
                  color: 'rgba(19, 173, 255, 0.5)',
                },
              },
            },
          ],
        },
      ],
    }
  },
}

export const color = [
  '#5470c6',
  '#91cc75',
  '#fac858',
  '#ee6666',
  '#73c0de',
  '#3ba272',
  '#fc8452',
  '#9a60b4',
  '#ea7ccc',
  '#f8ae45',
  '#ed32a3',
  '#8752e5',
  '#4bc3ff',
  '#e96d6b',
]

export const color2 = ['#385ee5', '#ee405c', '#51c77d', '#9cf2ff', '#6cb8df']
